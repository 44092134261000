<template>
    <div>
        <template v-if="filters === undefined">
            <el-checkbox-group v-model="checkedProductCategories" size="large">
                <el-checkbox class="ml-0 mr-1 mb-2 pb-2 border-bottom d-block" 
                    v-for="(product_category, id) in product_categories"
                    :key="id"
                    :label="product_category"
                    :name="product_category"
                    :checked="category_selected == id ? true : false"
                    @change="updateProductCategories">{{product_category}}
                </el-checkbox> 
            </el-checkbox-group>
        </template>
        <template v-else>
            <el-checkbox-group v-model="checkedFilters" size="large">
                <el-checkbox class="ml-0 mr-1 mb-2 pb-2 border-bottom d-block" 
                     v-for="filter in filters"
                    :key="filter.id"
                    :label="filter"
                    :name="filter"
                    :checked="false"
                    @change="updateFilters">{{filter}}
                </el-checkbox>
            </el-checkbox-group> 
        </template>
    </div>
</template>

<script>
    export default {
        
        props: ['product_categories', 'category_selected', 'filters'],

        data() {
            return {
                checkedFilters: [],
                checkedFiltersId: [],
                checkedProductCategories: [],
                checkedProductCategoriesId: [],
                env: false,
                done: false,
            }
        },
        methods: {
            trans(value){
                if (!value) return '';
                return  _.get(window.i18n, value);
            },
            updateProductCategories: function () {
                this.checkedProductCategoriesId = [];
                var arrayLength = this.checkedProductCategories.length;
                var product_category_name = '';
                for (var i = 0; i < arrayLength; i++) {
                    product_category_name = this.checkedProductCategories[i];
                    for (var key in this.product_categories) {
                        if (this.product_categories.hasOwnProperty(key))
                            if(this.product_categories[key] == product_category_name)
                                if(this.checkedProductCategories.indexOf(product_category_name)>-1)
                                    this.checkedProductCategoriesId.push(key);
                    }
                }
                EventBus.$emit('update-product-categories', this.checkedProductCategoriesId);
            },
            updateFilters() {

                this.checkedFiltersId = [];
                var arrayLength = this.checkedFilters.length;
                var filter_name = '';
                for (var i = 0; i < arrayLength; i++) {
                    filter_name = this.checkedFilters[i];
                    for (var key in this.filters) {
                        if (this.filters.hasOwnProperty(key))
                            if(this.filters[key] == filter_name)
                                if(this.checkedFilters.indexOf(filter_name)>-1)
                                    this.checkedFiltersId.push(key);
                    }
                }
                console.log(this.checkedFiltersId);

                EventBus.$emit('update-filters', this.checkedFiltersId);

            },
            getWeekNumber() {
                let date = new Date();
                var copiedDate = new Date(date.getTime());
                copiedDate.setUTCDate(copiedDate.getUTCDate() + 4 - (copiedDate.getUTCDay() || 7));
                var yearStart = new Date(Date.UTC(copiedDate.getUTCFullYear(), 0, 1));
                var weekNumber = Math.ceil(((copiedDate - yearStart) / 86400000 + 1) / 7);
                return weekNumber;
            },
        },
        mounted() {
	    },
        filters: {
            translate: function (value) {
                if (!value) return '';
                return _.get(window.i18n, value);
            }
        }
    }
</script>

