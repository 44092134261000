<template>
	<div class="row justify-content-center justify-content-md-start">
        <div class="col-12 text-right change-view mb-4">
            <el-button class="btn-change-view" type="primary" :disabled="currentStyle == 'horizontal' ? true : false" plain icon="fa fa-list" @click="changeProductsView('horizontal')"></el-button>
            <el-button class="btn-change-view" type="primary" :disabled="currentStyle == 'vertical' ? true : false" plain icon="fa fa-th" @click="changeProductsView('vertical')"></el-button>
        </div>
        <product 
            v-for="product in checkPriceDuration(products)"
            :key="product.id"
            :location_name="product.location_name"
            :product="product"
            :excursions="product.excursions"
            :category_selected="category_selected"
            :env="env">
        </product>		
        <!--  -->
	</div>
</template>

<script>
	import Product from './Product.vue'
	export default {
        props: ['list_products', 'category_selected'],
        components: {
            'product': Product,
        },
        data() {
            return {
                products: [],
                range_price: [0,2000],
                range_duration: [0,16],
                currentStyle: 'horizontal',
                env: false,
                done: false,
            }
        },
        methods: {
            trans(value){
                console.log(value);
                if (!value) return '';
                return  _.get(window.i18n, value);
            },
            checkPriceDuration: function (products) {
                var self = this;
                return products.filter(function (product) {
                    return  (product.price >= self.range_price[0] && product.price <= self.range_price[1]) && (product.duration >= self.range_duration[0] && product.duration <= self.range_duration[1])
                })
            },
            changeProductsView: function (new_style){
                if(this.currentStyle != new_style){

                    if(this.currentStyle =='vertical')
                        this.currentStyle = 'horizontal';
                    else
                        this.currentStyle = 'vertical';

                    EventBus.$emit('update-style', this.currentStyle);

                }
            },
            isMobile(){
                return (
                    (navigator.userAgent.match(/Android/i)) ||
                    (navigator.userAgent.match(/webOS/i)) ||
                    (navigator.userAgent.match(/iPhone/i)) ||
                    (navigator.userAgent.match(/iPod/i)) ||
                    (navigator.userAgent.match(/iPad/i)) ||
                    (navigator.userAgent.match(/BlackBerry/i))
                );
            },
            getWeekNumber() {
                let date = new Date();
                var copiedDate = new Date(date.getTime());
                copiedDate.setUTCDate(copiedDate.getUTCDate() + 4 - (copiedDate.getUTCDay() || 7));
                var yearStart = new Date(Date.UTC(copiedDate.getUTCFullYear(), 0, 1));
                var weekNumber = Math.ceil(((copiedDate - yearStart) / 86400000 + 1) / 7);
                return weekNumber;
            },
        },
    	created: function() {
            EventBus.$on('update-products', (product_categories, date, locations, filters) => {
            	let uri = '/api/products/bycategories';
            	axios.post(uri, {
                    product_categories: product_categories,
                    filters: filters,
            		locations: locations,
            		date: date,

            	}).then((response) => {
           			this.products = response.data;
        		});
	        });

            EventBus.$on('update-range-price', (new_range_price) => {
                this.range_price = new_range_price;
            });

            EventBus.$on('update-range-duration', (new_range_duration) => {
                this.range_duration = new_range_duration;
            });

            EventBus.$on('product-complete', (product) => {
                if(this.isMobile()!==null){
                    this.currentStyle = 'vertical';
                    EventBus.$emit('update-style', this.currentStyle);
                }
            });
        },
	    mounted() {
            this.products = this.list_products;
            if (this.list_products.length == 0) {
                let locations = [];
                switch (window.location.pathname) {
                    case '/fuerteventura':
                        locations = [5];
                        break;
                    case '/gran-canaria':
                        locations = [1];
                        break;
                    case '/granada':
                        locations = [3];
                        break;
                    case '/lanzarote':
                        locations = [2];
                        break;
                    case '/tenerife':
                        locations = [4];
                        break; 
                    default:
                        break;
                }
                let uri = '/api/products/bycategories';
            	axios.post(uri, {
                    product_categories: [],
                    filters: [],
            		locations: locations,
            		date: [],

            	}).then((response) => {
           			this.products = response.data;
        		});
            }
	    },
        filters: {
            translate: function (value) {
                if (!value) return '';
                return _.get(window.i18n, value);
            }
        }
	}
</script>
