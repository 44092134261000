<template>
	<div v-if="style" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4">
		<a :href="'/' + location_name + '/' + product.slug" class="card-tour">
		    <div class="card mb-4 shadow">
		        <img class="card-img-top" :alt="product.alt" :src="product.image" data-holder-rendered="true">
		        <div class="badge-header" style="position:absolute;top:5px;right:5px;">
                    <p class="reviews mb-0 h5 text-white">
                    	<small>
	                        <i class="fas fa-clock" style="-webkit-text-fill-color:#fff;"></i>
    	                    | <small>{{product.duration}} {{ this.trans('vue.hours' )}}</small>
                    	</small>
                    </p>
                </div>
		        <div class="card-body px-2 pt-1 pb-0">
		        	<div class="row no-gutters">
		        		<div class="col-12 name-tour">
			        		<p class="card-text my-0"><small class="text-muted">{{location_name}}</small></p>
			            	<h5 class="text-title-ellipsis mb-1">{{product.name}}</h5>
			        	</div>
			        </div>
		            <p class="my-1">
						<span v-html="product.stars"></span>
		            	| <small>{{product.reviews}} {{ this.trans('vue.reviews' )}}</small>
		            </p>
		            <p class="my-0">
					  	<span v-for="excursion in excursions">
					  		<button type="button" class="btn btn-outline-secondary btn-sm mr-1 mb-1">
					  			{{ excursion }}
					  		</button>
					  	</span>
		            </p>
					<h5 class="text-secondary mt-0 mb-2 h2 text-right price ">
						<span class="from">{{ this.trans('vue.from' )}}</span>
						{{this.product.price}}
						<small class="font-weight-bold">€</small>
					</h5>
		        </div>
                <div class="overlay card-body">
                    <p class="card-text my-0">
                        <small class="badge badge-light text-primary"><b>{{location_name}}</b></small>
                    </p>
                    <h6 class="text-white my-1">{{product.name}}</h6>
                    <p class="text-white text-left py-1" v-html="product.subtittle"></p>
					<p class="text-white text-left py-1">{{ product.code }} </p>
                    <h6 class="my-1 text-white">
                        <span v-html="product.stars"></span>
		            	| <small>{{product.reviews}} {{ this.trans('vue.reviews' )}}</small>
                    </h6>
                </div>
		    </div>
		</a>
	</div>

	<div v-else class="col-11 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 mb-4"> 
		<a :href="'/' + location_name + '/' + product.slug" class="card-tour">
			<div class="card mb-4 shadow" style="min-height:auto;"> 
				<div class="row align-items-stretch no-gutters">
					<div class="col-4 col-md-4 col-lg-5 col-xl-4 rounded-left" :style="product_bg">
					</div>
					<div class="col-8 col-md-8 col-lg-7 col-xl-8">
						<div class="row">
							<div class="col-12">
								<div class="card-body px-3 pt-2 pb-0">
						    		<h5 class="mb-1">{{ product.name }}</h5>
						    	</div>
							</div>
						</div>
						<div class="row align-items-stretch">
							<div class="col-md-8">
								<div class="card-body px-3 py-0">
						        	<p class="card-text my-0"><small class="text-muted">{{location_name}}</small></p>
						            <p class="my-1">
										<span v-html="product.stars"></span>
						            	| <small>{{product.reviews}} {{ this.trans('vue.reviews' )}}</small>
						            </p>

									<p class="d-none d-sm-block" v-html="product.description"></p>

									<p>{{ product.code }}</p>

									<p class="">
										<small>
											<i class="fas fa-clock"></i> |
											{{product.duration}} {{ this.trans('vue.hours' )}}
										</small>
		                    		</p>
						            <p class="my-0">
									  	<span v-for="excursion in excursions">
									  		<button type="button" class="btn btn-outline-secondary btn-sm mr-1 mb-1">
									  			{{ excursion }}
									  		</button>
									  	</span>
						            </p>
						         </div>
							</div>
							<div class="col-md-4 d-flex">
								<div class="card-body p-2 align-self-center text-center">
										<h5 class="text-secondary my-0 h2 price">
											<span class="from">{{ this.trans('vue.from' )}}</span><br>
											{{this.product.price}}
											<small class="font-weight-bold">€</small>
										</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</a>
		</div>

</template>


<script>
	export default {
        props: ['location_name', 'product', 'excursions', 'category_selected', 'env'],
		data() {
            return {
                produc_bg: '',
                style: false,
            }
        },
        methods: {
            trans(value){
                if (!value) return '';
                return  _.get(window.i18n, value);
        	},
			getWeekNumber() {
                let date = new Date();
                var copiedDate = new Date(date.getTime());
                copiedDate.setUTCDate(copiedDate.getUTCDate() + 4 - (copiedDate.getUTCDay() || 7));
                var yearStart = new Date(Date.UTC(copiedDate.getUTCFullYear(), 0, 1));
                var weekNumber = Math.ceil(((copiedDate - yearStart) / 86400000 + 1) / 7);
                return weekNumber;
            },
        },
        filters: {
            translate: function (value) {
                if (!value) return '';
                	return _.get(window.i18n, value);
            }
        },
       	created: function() {
	        EventBus.$on('update-style', (new_style) => {
                this.style = new_style =='vertical';
            });
            this.product_bg = 'background-image: url("'+ this.product.image +'");background-position: center;background-size: cover;'
            EventBus.$emit('product-complete', true);
		}
	}

</script>
