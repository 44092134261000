<template>
    <div>
        <p class="bg-light px-1 py-1 font-weight-bold mt-2">{{ $options.filters.translate('vue.duration')}}: {{this.range_duration[0]}}-{{this.range_duration[1]}} hours </p>
        <el-slider class="px-3"
            v-model="range_duration"
            range
            :step="1"
            :min="1"
            :max="72"
            @change="updateRangeDurations">
        </el-slider>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                range_duration: [1,72],
            }
        },
        methods: {
            trans(value){
                if (!value) return '';
                return  _.get(window.i18n, value);
            },
            updateRangeDurations: function () {
                EventBus.$emit('update-range-duration', this.range_duration);
            },
        },
        filters: {
            translate: function (value) {
                if (!value) return '';
                return _.get(window.i18n, value);
            }
        }
    }
</script>

